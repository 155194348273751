import React, { useState } from "react";

import { Link } from "react-router-dom";
import { Redirect } from "react-router";
//import useForm from 'react-hook-form'
import { Button, Form } from "reactstrap";
import { Progress } from "reactstrap";
import * as base from "./global";
import axios from "axios";
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";
import Topbar from "../Layout/Topbar";
import ActivityIndicator from "react-activity-indicator";
//npm install react-activity-indicator
import "react-activity-indicator/src/activityindicator.css";
import Alert from "react-bootstrap/Alert";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Basic from "./Basic";
import Basictwo from "./Basictwo";
import Basicthree from "./Basicthree";
import Basicfour from "./Basicfour";
import Basicfive from "./Basicfive";
import Modal from "react-modal";
import ImageIcon from "@mui/icons-material/Image";

import { addStyles, EditableMathField, StaticMathField } from "react-mathquill";

const Fillinthegapielts = (props) => {
  // Initial state for rows
  const [rows, setRows] = useState([{ sentence: "", answer: "" }]);
  const [rowsparagraph, setRowsparagraph] = useState([{ paragraph: "" }]);
  const [loading, setLoading] = useState(false);
  const [automemberid, setAutomemberid] = useState(props.match.params.id);
  const [examid, setExamid] = useState(props.match.params.ids); //setImageupload
  const [imageupload, setImageupload] = useState(null); //setImageupload
  const [examname, setExamname] = useState(props.match.params.idss); //paragraph
  const [paragraph, setParagraph] = useState(null); //paragraph
  const [text, setText] = useState(false);
  const [file, setFile] = useState(null);
  const [status, setStatus] = useState("");
  const [answertype, setAnswertype] = useState("");

  const [questionimage, setQuestionimage] = useState(null);
  const [questionimageupload, setQuestionimageupload] = useState(false);
  const [preview, setPreview] = useState(null);
  const [uploadPercentage, setUploadPercentage] = useState(0);
  const [questionimageupload_loader, setQuestionimageuploadLoader] =
    useState(false);
  const [questionanswer, setQuestionanswer] = useState("");
  const [uploadPercentage2, setUploadPercentage2] = useState(0);
  const [preview2, setPreview2] = useState("");

  const handleInputChange = (index, field, value) => {
    const newRows = [...rows];
    newRows[index][field] = value;
    setRows(newRows);
  };

  const handleFileChange = (e) => {
    if (automemberid === "") {
      alert("Insert the Question Type");
    } else {
      // Set selected file and update upload state
      const file = e.target.files[0];
      setQuestionimage(file);
      setQuestionimageupload(true);

      // Image preview logic
      const reader = new FileReader();
      reader.onloadend = function () {
        setPreview(reader.result);
      };
      reader.readAsDataURL(file);

      // Upload logic
      const options = {
        onUploadProgress: (progressEvent) => {
          const { loaded, total } = progressEvent;
          let percent = Math.floor((loaded * 100) / total);
          if (percent < 100) {
            setUploadPercentage(percent);
          }
        },
      };

      const fd = new FormData();
      fd.append("profileImg", file, file.name);
      fd.append("autoincrement", automemberid);
      fd.append("questiontype", "Text");

      setQuestionimageuploadLoader(true);

      axios
        .post(`${base.BASE_URL}/questionimageupload`, fd, options)
        .then((res) => {
          if (res.status === 200) {
            setQuestionimageuploadLoader(false);
            setUploadPercentage(100);
            setTimeout(() => setUploadPercentage(0), 1000);
            alert("File uploaded successfully");
          } else {
            setQuestionimageuploadLoader(false);
            alert("Failed to Upload");
          }
        })
        .catch((err) => {
          setQuestionimageuploadLoader(false);
          console.error(err);
          alert("Error uploading image");
        });
    }
  };

  const handleInputChangePara = (index, field, value) => {
    const newRows = [...rowsparagraph];
    newRows[index][field] = value;
    setRowsparagraph(newRows);
  };

  // Add a new row
  const addRow = () => {
    setRows([...rows, { sentence: "", answer: "" }]);
  };
  const addRowParagraph = () => {
    setRowsparagraph([...rowsparagraph, { paragraph: "" }]);
  };

  // Remove a row
  const removeRow = (index) => {
    const newRows = rows.filter((_, i) => i !== index);
    setRows(newRows);
  };

  // Remove a row
  const removeRowparagraph = (index) => {
    const newRows = rowsparagraph.filter((_, i) => i !== index);
    setRowsparagraph(newRows);
  };

  // Handle form submission (you can customize this part as needed)
  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Submitted Rows:", JSON.stringify(rows));
    console.log("Submitted Rows:", rows);
    let questiondata = JSON.stringify(rows);
    let questionparagraph = JSON.stringify(rowsparagraph);
    //insert
    if (automemberid == "") {
      alert("Insert the Required Fields");
    } else {
      // this.setState({ loaderfile: "loading" }); ///Adminloginareacheck  adminlogin Adminloginsetup
      setLoading(true);
      axios
        .post(
          base.BASE_URL + "/questionsaveall",
          {
            automemberid: automemberid,
            questiontype: "Text",
            Atype: "",
            Atitle: "",
            Btype: "",
            Btitle: "",
            Ctype: "",
            Ctitle: "",
            Dtype: "",
            Dtitle: "",
            Answer: "",
            answertype: answertype,
            // writtinganswer: "",
            writtinganswer: questionanswer,
            quiztype: "IELTS Fill in the Gap",
            titletypetext1: "",
            titletypetext2: "",
            titletypetext3: "",
            titletypetext4: "",
            titletypetext5: "",
            text: questiondata,
            text2: questionparagraph,
            text3: "",
            text4: "",
            text5: "",
          },
          {
            headers: {
              authorization: `bearer ${localStorage.getItem("token")}`,
            },
          }
        )
        .then(
          (response) => {
            //  alert(response.data.status)
            if (response.data.status == "found") {
              alert("Save Succesfully");
              setImageupload(true);
              console.log("...data file " + JSON.stringify(response.data));
              // this.setState({ loggdin: "start", loaderfile: "notloading" });
            } else if (response.data == "notfound") {
              alert("Not inserted");
            }
          },
          (error) => {
            console.log(error);
          }
        );
    }

    //end
  };

  const UploadanswerImage = (event) => {
    const file = event.target.files[0];
    if (!file) return;

    const reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onloadend = () => {
      setPreview2(reader.result);
    };

    const fd = new FormData();
    fd.append("profileImg", file, file.name);
    fd.append("autoincrement", automemberid); // Replace with actual value
    fd.append("questiontype", answertype); // Replace with actual value

    const options = {
      onUploadProgress: (progressEvent) => {
        const { loaded, total } = progressEvent;
        let percent = Math.floor((loaded * 100) / total);
        if (percent < 100) {
          setUploadPercentage2(percent);
        }
      },
    };

    axios
      .post(base.BASE_URL + "/answerImageeUpload", fd, options)
      .then((res) => {
        if (res.status === 200) {
          setUploadPercentage2(100);
          setTimeout(() => setUploadPercentage2(0), 1000);
        } else {
          alert("Failed to upload");
        }
      })
      .catch((error) => {
        console.error("Upload error:", error);
        alert("Upload failed. Please try again.");
      });
  };

  const handleanswertype = (event) => {
    setAnswertype(event.target.value);
  };

  return (
    <div>
      <Header />
      <Container style={{ marginTop: 86 }}>
        <h1
          style={{
            fontSize: 17,
            fontWeight: "bold",
            marginBottom: 20,
          }}
        >
          IELTS Question Setup
        </h1>
        {imageupload ? (
          <div>
            <input type="file" onChange={handleFileChange} />
            {preview && <img src={preview} alt="Preview" />}
            {questionimageupload_loader && (
              <div
                style={{
                  height: 50,
                  marginTop: 10,
                  marginBottom: 10,
                }}
              >
                Uploading... {uploadPercentage}%
              </div>
            )}
          </div>
        ) : (
          ""
        )}
        <form onSubmit={handleSubmit}>
          {rowsparagraph.map((row, index) => (
            <div
              key={index}
              style={{
                marginBottom: "10px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <div style={{ flex: 1, marginRight: "10px" }}>
                <textarea
                  type="text"
                  placeholder="Enter Paragraph"
                  value={row.paragraph}
                  onChange={(e) =>
                    handleInputChangePara(index, "paragraph", e.target.value)
                  }
                  style={{ width: "100%" }}
                />
              </div>
              <button
                type="button"
                onClick={() => removeRowparagraph(index)}
                style={{ marginLeft: "10px" }}
              >
                Remove
              </button>
            </div>
          ))}
          <div>
            <button
              type="button"
              onClick={addRowParagraph}
              style={{ marginTop: "10px", marginBottom: 10 }}
            >
              Add Paragraph
            </button>
          </div>

          {rows.map((row, index) => (
            <div
              key={index}
              style={{
                marginBottom: "10px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <div style={{ flex: 1, marginRight: "10px" }}>
                <textarea
                  type="text"
                  placeholder="Enter sentence"
                  value={row.sentence}
                  onChange={(e) =>
                    handleInputChange(index, "sentence", e.target.value)
                  }
                  style={{ width: "100%" }}
                />
              </div>
              <div style={{ flex: 1, marginRight: "10px" }}>
                <input
                  type="text"
                  placeholder="Enter answer"
                  value={row.answer}
                  onChange={(e) =>
                    handleInputChange(index, "answer", e.target.value)
                  }
                  style={{ width: "100%" }}
                />
              </div>
              <button
                type="button"
                onClick={() => removeRow(index)}
                style={{ marginLeft: "10px" }}
              >
                Remove
              </button>
            </div>
          ))}
          <button
            type="button"
            onClick={addRow}
            style={{ marginTop: "10px", marginBottom: 20 }}
          >
            Add Row
          </button>
          <div
            style={{
              marginBottom: "10px",
              display: "flex",
              alignItems: "center",
            }}
          >
            <p style={{ fontSize: 14, marginTop: 12 }}> Answer Type</p>
            <select
              onChange={handleanswertype}
              style={{
                padding: 6,
                backgroundColor: "#f2f2f2",
              }}
              className="form-control"
            >
              <option value="" selected disabled>
                {" "}
              </option>
              <option value="Text">Text</option>
              <option value="Image">Image</option>
              <option value="Audio">Audio</option>
            </select>
          </div>
          <div>
            <p
              style={{
                color: "#000",
                fontSize: 14,
                marginTop: 3,
              }}
            >
              Question Answer
            </p>
            <textarea
              onChange={(e) => setQuestionanswer(e.target.value)}
              value={questionanswer}
              style={{ width: "100%" }}
            />
          </div>
          <div
            style={{
              marginBottom: "10px",
              display: "flex",
              alignItems: "center",
            }}
          >
            <p
              style={{
                color: "#000",
                fontSize: 14,
                marginTop: 3,
              }}
            >
              Answer Image
            </p>
            <input
              type="file"
              onChange={UploadanswerImage}
              className="form-control"
              style={{ marginTop: 12 }}
            />
            {preview2 === "" ? (
              <ImageIcon
                style={{
                  width: 50,
                  height: 50,
                  marginTop: 5,
                }}
              />
            ) : (
              <img
                alt=""
                src={preview2}
                style={{
                  width: 50,
                  height: 50,
                  marginTop: 5,
                }}
              />
            )}
            {uploadPercentage2 > 0 && (
              <Progress bar color="warning" value={uploadPercentage2}>
                {uploadPercentage2}
              </Progress>
            )}
          </div>
          <div
            style={{
              marginBottom: "10px",
              display: "flex",
              alignItems: "center",
            }}
          >
            <button type="submit" style={{ marginTop: "10px" }}>
              Submit
            </button>
          </div>
        </form>
      </Container>
    </div>
  );
};

export default Fillinthegapielts;
