import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Button, Label } from "reactstrap";
import Header from "../Layout/Header";
import "react-activity-indicator/src/activityindicator.css";
import Container from "react-bootstrap/Container";
import { Spinner } from "react-bootstrap";
import { addExamType } from "../axios/student/api";
import { successToast } from "../page/library/toast";
import axios from "axios";
import * as base from "./global";
import { updateexamstype } from "../axios/student/api";

const Editexamtype = (props) => {
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [auto, setAuto] = useState(props.match.params.id);
  const [status, setStatus] = useState(1);
  const [type, setType] = useState("");
  const [loading, setLoading] = useState();
  const [packagetype, setPackagetype] = useState("");

  const handleName = (e) => {
    setName(e.target.value);
  };
  //handledescription
  const handledescription = (e) => {
    setDescription(e.target.value);
  };
 
  const handlePackagetype = (event) => {
    if (event.target.value == "Individual") {
      setPackagetype(event.target.value);
    } else {
      setPackagetype(event.target.value);
    }
  };


  const handleStatus = (e) => {
    setStatus(e.target.value);
  };
  const handletype = (e) => {
    setType(e.target.value);
  };

  const updateexamtypebtn = async () => {
    setLoading(true);
    try {
      // Construct URL with dynamic params
      const url =
        base.BASE_URL + `/updateexamtype/${auto}/${name}/${type}/${status}/${packagetype}/${description}`;
      // Make GET request using Axios
      const response = await axios.get(url);
      // Log the response
      console.log("Updated Package:", response.data);
      // Handle the success (you can update UI here if needed)
      setLoading(false);
      alert("Exam Type Updated Successfully");
    } catch (error) {
      setLoading(false);
      console.error("Error updating exam type:", error);
      alert("Failed to update exam type");
    }
  };
  useEffect(() => {
    // Make the axios request
    axios
      .get(base.BASE_URL + "/editexamtype/" + props.match.params.id)
      .then((res) => {
        const data = res.data[0];
        if (data) {
          console.log("data" + JSON.stringify(data));
          setName(data.title);
          setType(data.type);
          setStatus(data.status);
          setPackagetype(data.packagetype)
        }
      })
      .catch((error) => {
        console.error("There was an error fetching the data!", error);
      });
  }, [props.match.params.id]);

  return (
    <div>
      <Header />
      <Container style={{ marginTop: 86 }}>
        <div className="container-fluid">
          {/* Page Heading */}
          <div class="card position-relative">
            <div class="card-header py-3">
              <Link to="/Listofexamtypes">Back</Link>
            </div>
            <div class="card-body">
              <div className="row">
                <div className="col-md-12">
                  <form className="user">
                    <div class="form-content">
                      <div>
                        <div className="form-header">
                          <h3 class="m-0 font-weight-bold text-primary">
                            Update Exam Type
                          </h3>
                        </div>
                        <div className="form-row">

                          <div
                            className="form-holder col-md-4"
                            style={{ padding: 16 }}
                          >
                            <Label>Select Package Type {packagetype} </Label>
                            <select
                              className="combox"
                              onChange={(e) => handlePackagetype(e)}
                              value={packagetype}
                            >
                              <option value={packagetype}>{packagetype}</option>
                              <option value="Package">Package</option>
                              <option value="Individual">Individual</option>
                            </select>
                          </div>
                          <div
                            className="form-holder col-md-4"
                            style={{ padding: 16 }}
                          >
                            <Label>Exam Type Name</Label>
                            <input
                              type="text"
                              className="form-control form-control-user"
                              placeholder="Exam Type Name"
                              onChange={(e) => handleName(e)}
                              value={name}
                            />
                          </div>
                          <div
                            className="form-holder col-md-4"
                            style={{ padding: 16 }}
                          >
                              <Label>Description about Package</Label>
                            <textarea
                              rows="5"
                              placeholder="Type something..."
                              value={description}
                              onChange={(e) => handledescription(e)}
                            />
                          
                          </div>
                          <div
                            className="form-holder col-md-4"
                            style={{ padding: 16 }}
                          >
                              <Label>Type</Label>
                            <select
                              onChange={(e) => handletype(e)}
                              value={type}
                              className="combox"
                            >
                              <option value={type}>{type}</option>
                              <option value="BD Exams">BD Exams</option>
                              <option value="IELTS">IELTS</option>
                            </select>
                          </div>

                          <div
                            className="form-holder col-md-4"
                            style={{ padding: 16 }}
                          >
                            <Label>Status</Label>
                            <select
                              onChange={(e) => handleStatus(e)}
                              value={status}
                              className="combox"
                            >
                              {status === 1 ? (
                                <option value="1" selected>
                                  Active
                                </option>
                              ) : (
                                ""
                              )}
                              {status === 0 ? (
                                <option value="0" selected>
                                  Inactive
                                </option>
                              ) : (
                                ""
                              )}
                              <option value="0">Inactive</option>
                            </select>
                          </div>
                        </div>
                        <div className="form-row">
                          <div
                            className="form-holder col-md-6"
                            style={{ padding: 10 }}
                          >
                            <Button
                              type="button"
                              className="btn btn-primary btn-user btn-block"
                              onClick={updateexamtypebtn}
                              style={{ marginTop: 20 }}
                            >
                              {loading ? (
                                <Spinner
                                  style={{ marginBottom: 27 }}
                                  animation="border"
                                  variant="danger"
                                />
                              ) : (
                                "update Exam Type"
                              )}
                            </Button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};
export default Editexamtype;
