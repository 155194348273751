import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Button, Label } from "reactstrap";
import Header from "../Layout/Header";
import "react-activity-indicator/src/activityindicator.css";
import Container from "react-bootstrap/Container";
import { Spinner } from "react-bootstrap";
import { addPackage, getExamTypes } from "../axios/student/api";
import { successToast, errorToast } from "./library/toast";
import axios from "axios";
import * as base from "./global";
import { typelist } from "../utils/constants";
import Select from "react-select";


function AddPackage() {
  const [packagename, setPackagename] = useState("");
  const [classname, setClassname] = useState("");
  const [version, setversion] = useState("Bangla Version");
  const [group, setgroup] = useState("Science");
  const [price, setPrice] = useState(50);
  const [examTypes, setExamTypes] = useState([]);
  const [selectedQuizType, setSelectedQuizType] = useState("MCQ");
  const [packagetype, setPackagetype] = useState("");
  const [loading, setLoading] = useState();
  const [query, setQuery] = useState(""); // setExamname setExamid
  const [examname, setExamname] = useState(""); //
  const [examid, setExamid] = useState(""); //  setExamid
  const [suggestions, setSuggestions] = useState([]);

  const [options, setOptions] = useState([]); //statelist
  const [statelist, setStatelist] = useState([]); //statelist
  const [classdata, setClassdata] = useState([]); //classdata
  const [selectedOption, setSelectedOption] = useState(null);
  const [country, setCountry] = useState("");
  const [schoolname, setSchoolname] = useState("");
  const [maincategory, setMaincategory] = useState("");
  const [examnameshow, setExamnameshow] = useState(false);
  //
  const handleName = (e) => {
    setPackagename(e.target.value);
  };

  const handlePrice = (e) => {
    setPrice(e.target.value);
  };

  const handleChange = (selected) => {
    setSelectedOption(selected);
    setExamid(selected.value);
    setExamname(selected.label);
  };


  useEffect(() => {
    fnExamTypes();
      axios
      .get(base.BASE_URL + `/searchexamname`) // Replace with your API URL
      .then((response) => {
        const examOptions = response.data.map((exam) => ({
          value: exam._id,
          label: exam.examname + '-' + exam.classname + '-' + exam.xgroup,
        }));
        setOptions(examOptions);
      })
      .catch((error) => console.error("Error fetching exams:", error));
  }, []);

  const fnExamTypes = () => {
    getExamTypes({})
      .then((res) => {
        if (res.data) {
          setExamTypes(res.data);
        } else {
          setExamTypes([]);
        }
      })
      .catch(() => {
        setExamTypes([]);
      });
  };

  const createuser = () => {
    if (packagename !== "") {
      setLoading(true);
      const postObj = {
        packagename: packagename,
        type: selectedQuizType,
        classname: classname,
        versionname: version,
        group: group,
        price: price,
        packagetype: packagetype,
        examid: examid,
        examname: examname,
        maincategory: country,
        subcategory: schoolname
      };
      addPackage(postObj)
        .then((res) => {
          if (res.data.message === "Created") {
            successToast("Package Created Successfully.");
          }
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
        });
    } else {
      errorToast("Fill out form");
    }
  };
  const handlepackagetype = (event) => {
    setPackagetype(event.target.value);
  };
  const handleExamType = (event) => {
    setSelectedQuizType(event.target.value);
  };
  const handleVersion = (event) => {
    setversion(event.target.value);
  };
  const handleGroup = (event) => {
    setgroup(event.target.value);
  };
  const getexamname = (id, examname) => {
    setExamname(examname);
    setExamid(id);
    setQuery(examname); // Set input value to selected name
    setSuggestions([]); // Clear suggestions after selection
    setPackagename(examname);
    //setQuery(examname)
  };
  const handleClass = (e) => {
    alert(e.target.value);
    setClassname(e.target.value);
  };

  const handlecountry = (event) => {
    setCountry(event.target.value);
    //  alert(' ccountry ' + event.target.value);
    ///////////////////////////////
    axios
      .get(base.BASE_URL + "/getschoollist/" + event.target.value, {
        headers: {
          authorization: `bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((result) => {
        setStatelist(result.data);
        console.log(" state list " + result.data);
      });
    ///////////////////////////////
  };
  const handleschoolcollegename = (event) => {
    setSchoolname(event.target.value);
    //  alert(' classlist ' + event.target.value)
    axios
      .get(base.BASE_URL + "/classlist/1/1000", {
        /*   headers: {
            authorization: `bearer ${token}`
          } */
      })
      .then((res) => {
        const filteredData = res.data.filter(
          (item) =>
            item.inistutetype === country &&
            item.inistuteid === event.target.value
        );
        console.log("classlist here" + JSON.stringify(filteredData));
        //this.setState({ classdata: filteredData });
        setClassdata(filteredData);
      });
  };

  const handlePackagetype = (event) => {
    if (event.target.value == "Individual") {
      setPackagetype(event.target.value);
      setExamnameshow(true)
    } else {
      //Package
      setExamnameshow(false)
      setPackagetype(event.target.value);
      setExamname("");
    }
  };

  return (
    <div>
      <Header />
      <Container style={{ marginTop: 86 }}>
        <div className="container-fluid">
          {/* Page Heading */}
          <div class="card position-relative">
            <div class="card-header py-3">
              <Link to="/Listofpackages">Back</Link>
            </div>
            <div class="card-body">
              <div className="row">
                <div className="col-md-12">
                  <form className="user">
                    <div class="form-content">
                      <div>
                        <div className="form-header">
                          <h3 class="m-0 font-weight-bold text-primary">
                            Add New Package
                          </h3>
                        </div>
                        <p style={{ color: "#000" }}>
                          Please fill with your details
                        </p>
                        <div className="form-row"></div>
                        <div className="form-row">
                          <div
                            className="form-holder col-md-4"
                            style={{ padding: 16 }}
                          >
                            <Label>Main Category </Label>
                            <select
                              onChange={handlecountry}
                              style={{ padding: 6, backgroundColor: "#f2f2f2" }}
                              className="form-control"
                            >
                              <option value={maincategory}>
                                {maincategory}
                              </option>
                              {typelist?.map((result) => {
                                return (
                                  <option value={result.title}>
                                    {result.title}
                                  </option>
                                );
                              })}
                            </select>
                          </div>
                          <div
                            className="form-holder col-md-4"
                            style={{ padding: 16 }}
                          >
                          <lable>School/college </lable>  
                            <select
                              className="form-control"
                              onChange={handleschoolcollegename}
                            >
                              <option selected value=""></option>
                              {statelist.map((result) => {
                                return (
                                  <option value={result._id}>
                                    {result.name}
                                  </option>
                                );
                              })}
                            </select>
                          </div>
                          <div
                            className="form-holder col-md-4"
                            style={{ padding: 16 }}
                          >
                            <Label>Select Package Type</Label>
                            <select
                              className="combox"
                              onChange={(e) => handlePackagetype(e)}
                             // onChange={(e) => setPackagetype(e.target.value)}
                            //  onChange={(e) => setPackagetype(e.target.value)}
                              value={packagetype}
                            >
                              <option value="">Select Package Type</option>
                              <option value="Package">Package</option>
                              <option value="Individual">Individual</option>
                            </select>
                          </div>
                          {
                            examnameshow ? 
                            <div
                            className="form-holder col-md-4"
                            style={{ padding: 16 }}
                          >
                            <Label>Exam Name </Label>
                            <Select
                              options={options}
                              value={selectedOption}
                              onChange={handleChange}
                              isSearchable
                              placeholder="Select an exam..."
                            />
                          </div>
                          : ''
                          }
                        
                          <div
                            className="form-holder col-md-4"
                            style={{ padding: 16 }}
                          >
                            <Label>Package Name</Label>
                            <input
                              type="text"
                              className="form-control form-control-user"
                              placeholder="Package Name"
                              onChange={(e) => handleName(e)}
                              value={packagename}
                            />
                          </div>
                          <div
                            className="form-holder col-md-4"
                            style={{ padding: 10 }}
                          >
                            <Label>Exam Type </Label>
                            <select
                              className="combox"
                              onChange={handleExamType}
                              value={selectedQuizType}
                            >
                              {examTypes.map((item) => {
                                return (
                                  <option key={item.id} value={item.id}>
                                    {item.title}
                                  </option>
                                );
                              })}
                            </select>
                          </div>
                          <div
                            className="form-holder col-md-6"
                            style={{ padding: 16 }}
                          >
                            <Label>Class</Label>
                            <select
                              onChange={(e) => handleClass(e)}
                              style={{ padding: 6, backgroundColor: "#f2f2f2" }}
                              className="form-control"
                            >
                              <option value=""></option>
                              {classdata.map((result) => {
                                return (
                                  <option
                                    key={result._id}
                                    value={result.xclassname}
                                    selected={
                                      classname === result.xclassname
                                        ? true
                                        : false
                                    }
                                  >
                                    {result.xclassname}
                                  </option>
                                );
                              })}
                            </select>
                          </div>
                          <div
                            className="form-holder col-md-6"
                            style={{ padding: 16 }}
                          >
                            <Label>Version</Label>
                            <select
                              onChange={(e) => handleVersion(e)}
                              value={version}
                              className="combox"
                            >
                              <option value="">Select Version </option>
                              <option value="Bangla Version">
                                Bangla Version
                              </option>
                              <option value="English Version">
                                English Version
                              </option>
                            </select>
                          </div>
                          <div
                            className="form-holder col-md-6"
                            style={{ padding: 16 }}
                          >
                            <Label>Group</Label>
                            <select
                              onChange={(e) => handleGroup(e)}
                              value={group}
                              className="combox"
                            >
                              <option value="">Select Group </option>
                              <option value="Science">Science</option>
                              <option value="Arts">Arts</option>
                              <option value="Commerce">Commerce</option>
                            </select>
                          </div>
                          <div
                            className="form-holder col-md-6"
                            style={{ padding: 16 }}
                          >
                            <Label>Price</Label>
                            <input
                              type="number"
                              className="form-control form-control-user"
                              placeholder="Price"
                              onChange={(e) => handlePrice(e)}
                              value={price}
                            />
                          </div>
                        </div>
                        <div className="form-row">
                          <div
                            className="form-holder col-md-6"
                            style={{ padding: 10 }}
                          >
                            <Button
                              type="button"
                              className="btn btn-primary btn-user btn-block"
                              onClick={createuser}
                              style={{ marginTop: 10, width: 200 }}
                            >
                              {loading ? (
                                <Spinner
                                  style={{ marginBottom: 27 }}
                                  animation="border"
                                  variant="danger"
                                />
                              ) : (
                                "Add Package"
                              )}
                            </Button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
}
export default AddPackage;
