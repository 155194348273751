import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Button, Label } from "reactstrap";
import Header from "../Layout/Header";
import "react-activity-indicator/src/activityindicator.css";
import Container from "react-bootstrap/Container";
import { Spinner } from "react-bootstrap";
import * as base from "./global";
import Select from "react-select";

import {
  addPackage,
  getExamTypes,
  getPackage,
  updatePackage,
} from "../axios/student/api";
import { successToast, errorToast } from "./library/toast";
import { useParams } from "react-router-dom";
import axios from "axios";
import { typelist } from "../utils/constants"; //../utils/constants  ../../src/utils/constants

function EditPackage({ props }) {
  const { id } = useParams();
  const [packagename, setPackagename] = useState("");
  const [classname, setClassname] = useState("");
  const [version, setversion] = useState("");
  const [group, setgroup] = useState("");
  const [price, setPrice] = useState(50);
  const [examTypes, setExamTypes] = useState([]); //setSuggestions
  const [suggestions, setSuggestions] = useState([]); //setSuggestions
  const [selectedQuizType, setSelectedQuizType] = useState("");
  const [loading, setLoading] = useState();
  const [examid, setExamid] = useState("");
  const [examname, setExamname] = useState("");
  const [packagetype, setPackagetype] = useState("");
  const [query, setQuery] = useState(""); // setExamname setExamid

  const [options, setOptions] = useState([]); //statelist
  const [statelist, setStatelist] = useState([]); //statelist
  const [classdata, setClassdata] = useState([]);  //classdata
  const [selectedOption, setSelectedOption] = useState(null);
  const [country,setCountry] = useState('');
  const [schoolname,setSchoolname] = useState('');
  const [maincategory,setMaincategory] = useState('');

  const handleChange = (selected) => {
    //alert(JSON.stringify(selected));
    //alert(selected.value);
    setSelectedOption(selected);
    setExamid(selected.value);
    setExamname(selected.label);

    // handleExamChange(selected); // Pass selected exam to parent component
  };

  const handleName = (e) => {
    setPackagename(e.target.value);
  };
  const handleClass = (e) => {
    alert(e.target.value);
    setClassname(e.target.value);
  };
  const handlePackagetype = (event) => {
    if (event.target.value == "Individual") {
      setPackagetype(event.target.value);
    } else {
      //Package
      setPackagetype(event.target.value);
      setExamname("");
    }
  };
  const handlePrice = (e) => {
    setPrice(e.target.value);
  };
  const handleexamname = (e) => {
    // setQuery(e.target.value)
    setExamname(e.target.value);
  };
  const getexamname = (id, examname) => {
    setExamname(examname);
    setExamid(id);
    setQuery(examname); // Set input value to selected name
    setSuggestions([]); // Clear suggestions after selection
    //setQuery(examname)
  };

  const fnGetPackage = () => {
    getPackage(id)
      .then((res) => {
        if (res.data) {
          if (res.status === 200) {
            setPackagename(res.data.packagename);
            setExamname(res.data.examname);
            setExamid(res.data.examid)
            setSelectedQuizType(res.data.type);
            setClassname(res.data.class);
            setPrice(res.data.price);
            setversion(res.data.versionname);
            setgroup(res.data.group);
            setPackagetype(res.data.packagetype);
            setQuery(res.data.examid);
            setMaincategory(res.data.maincategory);
            setSchoolname(res.data.subcategory);
          }
        }
      })
      .catch(() => {});
  };

  useEffect(() => {
    if (id) {
      fnGetPackage();
    }
    fnExamTypes();
    axios
      .get(base.BASE_URL + `/searchexamname`) // Replace with your API URL
      .then((response) => {
        const examOptions = response.data.map((exam) => ({
          value: exam._id,
          label: exam.examname + '-' + exam.classname + '-' + exam.xgroup,
        }));
        setOptions(examOptions);
      })
      .catch((error) => console.error("Error fetching exams:", error));
    /*  } else {
      setSuggestions([]);
    } */
  }, []); //query

  const fnExamTypes = () => {
    getExamTypes({})
      .then((res) => {
        if (res.data) {
          setExamTypes(res.data);
        } else {
          setExamTypes([]);
        }
      })
      .catch(() => {
        setExamTypes([]);
      });
  };

  const updatePackageinfo = () => {
    alert(classname);
    if (packagename !== "") {
      //&& classname !== ''
      setLoading(true);
      const postObj = {
        packagename: packagename,
        maincategory: country,
        subcategory: schoolname,
        type: selectedQuizType,
        class: classname,
        versionname: version,
        group: group,
        price: price,
        packagetype: packagetype,
        examid: examid,
        examname: examname
      };
      updatePackage(postObj, id)
        .then((res) => {
          if (res.status === 200) {
            successToast("Package updated Successfully.");
          }
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
        });
    } else {
      errorToast("Fill out form");
    }
  };

  const handleExamType = (event) => {
    setSelectedQuizType(event.target.value);
  };
  const handleVersion = (event) => {
    setversion(event.target.value);
  };
  const handleGroup = (event) => {
    setgroup(event.target.value);
  };
 const handlecountry = (event) => {
    setCountry(event.target.value)
  //  alert(' ccountry ' + event.target.value);
    ///////////////////////////////
    axios.get(base.BASE_URL + '/getschoollist/' + event.target.value,
        {
            headers: {
                authorization: `bearer ${localStorage.getItem('token')}`
            }
        })
        .then(result => {
           setStatelist(result.data);
           console.log(' state list ' + result.data)
        });
    ///////////////////////////////
}
  const handleschoolcollegename = (event) => {
    setSchoolname(event.target.value)
  //  alert(' classlist ' + event.target.value)
    axios.get(base.BASE_URL + '/classlist/1/1000', {
        /*   headers: {
            authorization: `bearer ${token}`
          } */
    }).then(res => {
        const filteredData = res.data.filter(item => item.inistutetype === country && item.inistuteid === event.target.value);
        console.log('classlist here' + JSON.stringify(filteredData))
        //this.setState({ classdata: filteredData });
        setClassdata(filteredData);
    });
  }

  return (
    <div>
      <Header />
      <Container style={{ marginTop: 86 }}>
        <div className="container-fluid">
          {/* Page Heading */}
          <div class="card position-relative">
            <div class="card-header py-3">
              <Link to="/Listofpackages">Back</Link>
            </div>
            <div class="card-body">
              <div className="row">
                <div className="col-md-12">
                  <form className="user">
                    <div class="form-content">
                      <div>
                        <div className="form-header">
                          <h3 class="m-0 font-weight-bold text-primary">
                            Edit Package info
                          </h3>
                        </div>
                        <div className="form-row">
                          <div
                            className="form-holder col-md-4"
                            style={{ padding: 16 }}
                          >
                            <Label>Main Category </Label>
                            <select
                              onChange={handlecountry}
                              style={{ padding: 6, backgroundColor: "#f2f2f2" }}
                              className="form-control"
                            >
                              <option value={maincategory}>{maincategory}</option>
                              {typelist?.map((result) => {
                                return (
                                  <option value={result.title}>
                                    {result.title}
                                  </option>
                                );
                              })}
                            </select>
                          </div>
                          <div
                            className="form-holder col-md-4"
                            style={{ padding: 16 }}
                          >
                            <Label>Select Package Type {packagetype} </Label>
                            <select
                              className="combox"
                              onChange={(e) => handlePackagetype(e)}
                              //  onChange={(e) => setPackagetype(e.target.value)}
                              //  onChange={(e) => setPackagetype(e.target.value)}
                              value={packagetype}
                            >
                              <option value={packagetype}>{packagetype}</option>
                              <option value="Package">Package</option>
                              <option value="Individual">Individual</option>
                            </select>
                          </div>
                          <div
                            className="form-holder col-md-4"
                            style={{ padding: 16 }}
                          >
                            <Label>
                              Exam Name
                              <p>
                                {" "}
                              
                              </p>
                            </Label>
                            <Select
                              options={options}
                              value={selectedOption}
                              onChange={handleChange}
                              isSearchable
                              placeholder="Select an exam..."
                            />
                          </div>

                          <div
                            className="form-holder col-md-6"
                            style={{ padding: 16 }}
                          >
                            <Label>Package Name</Label>
                            <input
                              type="text"
                              className="form-control form-control-user"
                              placeholder="Package Name"
                              onChange={(e) => handleName(e)}
                              value={packagename}
                            />
                          </div>
                          <div
                            className="form-holder col-md-6"
                            style={{ padding: 10 }}
                          >
                            <Label>Exam Type </Label>
                            <select
                              className="combox"
                              onChange={handleExamType}
                              value={selectedQuizType}
                            >
                              {examTypes.map((item) => {
                                return (
                                  <option key={item.id} value={item.id}>
                                    {item.title}
                                  </option>
                                );
                              })}
                            </select>
                          </div>

                        <div className="form-holder col-md-6" style={{ padding: 10 }}>
                        School/college {schoolname}
                        <select className="form-control" 
                        onChange={handleschoolcollegename}>
                        <option selected value=""></option>
                        {
                            statelist.map((result) => {
                                return (
                                    <option value={result._id}>{result.name}</option>
                                )
                            })}
                        </select>
                        </div>

                          <div
                            className="form-holder col-md-6"
                            style={{ padding: 16 }}
                          >
                            <Label>Class {classname}</Label>

                             <select
                             onChange={(e) => handleClass(e)}
                              style={{ padding: 6, backgroundColor: "#f2f2f2" }}
                              className="form-control"
                            >
                             <option value=""></option>
                            {
                                classdata.map((result) => {
                                    return (
                                        <option
                                            key={result._id}
                                            value={result.xclassname}
                                           selected={classname === result.xclassname ? true : false}
                                        >
                                            {result.xclassname}
                                        </option>
                                    )
                                })}
                            </select>
                          </div>
                          <div
                            className="form-holder col-md-6"
                            style={{ padding: 16 }}
                          >
                            <Label>Version</Label>
                            <select
                              onChange={(e) => handleVersion(e)}
                              value={version}
                              className="combox"
                            >
                                <option value=""></option>
                              <option value="Bangla Version">
                                Bangla Version
                              </option>
                              <option value="English Version">
                                English Version
                              </option>
                            </select>
                          </div>
                          <div
                            className="form-holder col-md-6"
                            style={{ padding: 16 }}
                          >
                            <Label>Group</Label>
                            <select
                              onChange={(e) => handleGroup(e)}
                              value={group}
                              className="combox"
                            >
                                <option value=""></option>
                              <option value="Science">Science</option>
                              <option value="Arts">Arts</option>
                              <option value="Commerce">Commerce</option>
                            </select>
                          </div>
                          <div
                            className="form-holder col-md-6"
                            style={{ padding: 16 }}
                          >
                            <Label>Price</Label>
                            <input
                              type="number"
                              className="form-control form-control-user"
                              placeholder="Price"
                              onChange={(e) => handlePrice(e)}
                              value={price}
                            />
                          </div>
                        </div>
                        <div className="form-row">
                          <div
                            className="form-holder col-md-6"
                            style={{ padding: 10 }}
                          >
                            <Button
                              type="button"
                              className="btn btn-primary btn-user btn-block"
                              onClick={updatePackageinfo}
                              style={{ marginTop: 20 }}
                            >
                              {loading ? (
                                <Spinner
                                  style={{ marginBottom: 27 }}
                                  animation="border"
                                  variant="danger"
                                />
                              ) : (
                                "Update Package"
                              )}
                            </Button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
}
export default EditPackage;
